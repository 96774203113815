import React from 'react'
import {graphql } from 'gatsby'
import 'twin.macro'

import Layout from "../components/layout"
import SEO from "../components/seo"


const Faq = ({data, location}) => {
  const siteTitle = data.site.siteMetadata.title
  const html = data.Faq.contentLongText.childMarkdownRemark.html

  return (
    <Layout location={location} title={siteTitle} logoImage={data.logoImage}>
    <SEO title="Culture Yard Frequently Asked Questions" description={data.Faq.description.description} />
      <h1 tw="md:px-40 text-center">Culture Yard Frequently Asked Questions</h1>
      <section tw="md:px-40" dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>

  )

}


export default Faq


export const data = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    logoImage:contentfulAsset(title: {eq: "logo"}) {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyContentfulFluid
      }
    }
    Faq:contentfulStaticPage(title: {eq: "Frequently Asked Questions"}) {
      title
      description {
        description
      }
      contentLongText {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
